import classNames from "classnames";
import React from "react";
import { useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import Image from "../../media/Image";
import styles from "./CustomSelect.scss";
import { CUSTOM_SELECT_CLASS } from "./constants";
import { FormattedMessage } from "react-intl";

const msgKey = "customSelect";

export const CustomSelect = ({
    value,
    children,
    onChange,
    onCreate,
    placeholder,
    disabled,
    busy,
    isClearable = true,
    isSearchable = false,
    filterOption,
    noOptionsMessage,
    menuPlacement = "auto",
    maxLength = 200
}) => {
    const navigate = useNavigate();

    const handleChange = (opt) => {
        if (opt?.label?.props?.link) {
            navigate(opt?.label?.props?.link);
        } else {
            onChange && onChange(opt?.value);
        }
    };

    let selectedChild = null;
    React.Children.forEach(children, (child) => {
        if (child && child?.props?.value === value) {
            selectedChild = React.cloneElement(child, {
                selected: true
            });
        }
    });

    const SelectComponent = onCreate ? CreatableSelect : Select;
    return (
        <SelectComponent
            classNamePrefix={CUSTOM_SELECT_CLASS}
            placeholder={placeholder}
            isLoading={busy}
            disabled={disabled}
            menuPlacement={menuPlacement}
            menuPortalTarget={document.body}
            filterOption={filterOption}
            noOptionsMessage={noOptionsMessage || <FormattedMessage id={`${msgKey}.noOptions`} />}
            formatCreateLabel={(input) => <FormattedMessage id={`${msgKey}.createOption`} values={{ input }} />}
            components={{
                Input: (props) => <components.Input {...props} maxLength={maxLength} />
            }}
            styles={{
                control: (provided, state) => ({
                    ...provided,
                    height: "40px"
                })
            }}
            theme={(theme) => ({
                ...theme,
                borderRadius: "3px",
                colors: {
                    ...theme.colors,
                    primary: "#f5f5f5",
                    primary25: "#f5f5f5"
                }
            })}
            value={
                value !== null && value !== undefined
                    ? {
                          value,
                          label: selectedChild
                      }
                    : null
            }
            options={React.Children.map(children, (child) => {
                if (child?.type === OptionItem) {
                    return {
                        value: child.props?.value,
                        label: child
                    };
                }
            })}
            closeMenuOnScroll={(evt) => {
                return evt?.target?.className?.indexOf(CUSTOM_SELECT_CLASS) < 0;
            }}
            onChange={handleChange}
            onCreateOption={onCreate}
            isClearable={isClearable}
            isSearchable={isSearchable}
        />
    );
};

export const OptionItem = ({ symbol, label, description, append, selected, onClick }) => {
    return (
        <div
            className={classNames(styles.option, {
                [styles.clickable]: onClick,
                [styles.selected]: selected
            })}
            onClick={onClick}
        >
            {symbol && (
                <div className={classNames(styles.symbol, { [styles.image]: symbol?.type === Image })}>{symbol}</div>
            )}
            <div className={styles.label}>
                {label}
                <div className={styles.description}>{description}</div>
            </div>
            {append && <div className={styles.append}>{append}</div>}
        </div>
    );
};
