import React, { Suspense, lazy } from "react";
import { Curtain } from "./components/misc/Curtain";

const AppRouter = lazy(() =>
    import(/* webpackChunkName: "wrapper" */ "./AppRouter").then((module) => ({ default: module.AppRouter }))
);

export const AppEntry = () => {
    return (
        <Suspense fallback={<Curtain label="Initializing" />}>
            <AppRouter />
        </Suspense>
    );
};
