import React from "react";
import { ItemList } from "../../list/ItemList";
import Image from "../../media/Image";
import { TitleDescriptionText } from "../../typography/TitleDescriptionText";

export const UserBadge = ({ user, description }) => {
    return (
        <ItemList>
            <ItemList.Item prepend={<Image rounded icon="user" iconColor="primary" src={user?.picture?.url} />}>
                <TitleDescriptionText title={user?.fullName} description={description || user.email} />
            </ItemList.Item>
        </ItemList>
    );
};
