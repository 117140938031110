import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { downloadUserLogReview } from "../../../api/vessel/report/userLogReview";
import { Box, BoxContent, BoxTitle } from "../../../components/box/Box";
import { Button } from "../../../components/button/Button";
import { AuthContext } from "../../../contexts/Auth";
import { toRelativeDate, monthsDiff } from "../../../utils/dateUtils";
import { ButtonGroup } from "../../button/ButtonGroup";
import { ConfirmActionButton } from "../../button/ConfirmActionButton";
import { Col } from "../../grid/Col/Col";
import { Row } from "../../grid/Row/Row";
import Image from "../../media/Image";
import Badge from "../../misc/Badge";
import { PopupLoader } from "../../notify/PopupLoader";
import { toast } from "../../notify/Toast";
import { Text } from "../../typography/Text";
import { MonthLogSummaryList } from "./MonthLogSummaryList/MonthLogSummaryList";
import { MonthYearBrowser } from "./MonthYearBrowser";
import { TimeLogList } from "./TimeLogList";
import { TimeTable } from "./TimeTable";

const msgKey = "userTimeLog";

export const UserTimeLog = ({
    initialUnit,
    activeDate,
    setActiveDate,
    monthList,
    onClickEntry,
    entriesList,
    busyLoading,
    onRegisterClick,
    onClickHour,
    onCreateReview,
    onDeleteReview
}) => {
    const { me } = useContext(AuthContext);
    const [unit, setUnit] = useState(initialUnit || "MONTH");
    const [viewMode, setViewMode] = useState("TABLE");
    const [monthSummary, setMonthSummary] = useState(null);
    const [busyDeletingReview, setBusyDeletingReview] = useState(false);
    const [busyDownloadingReview, setBusyDownloadingReview] = useState(false);

    useEffect(() => {
        if (unit === "MONTH" && activeDate && monthList) {
            setMonthSummary(monthList?.find((m) => m.month === activeDate.getMonth() + 1));
        } else {
            setMonthSummary(null);
        }
    }, [activeDate, unit, monthList]);

    const changeActiveMonth = (month) => {
        setUnit("MONTH");
        const newDate = new Date(activeDate);
        newDate.setMonth(month - 1);
        setActiveDate(newDate);
    };

    const handleDeleteReview = async (review) => {
        setBusyDeletingReview(true);
        await onDeleteReview(review);
        setBusyDeletingReview(false);
    };

    const handleDownloadReview = async (review) => {
        setBusyDownloadingReview(true);
        try {
            await downloadUserLogReview(review);
        } catch (e) {
            toast.error({ message: <FormattedMessage id={`${msgKey}.downloadLogError`} /> });
        }
        setBusyDownloadingReview(false);
    };

    return (
        <Box>
            <BoxTitle>
                <FormattedMessage id={`${msgKey}.timeLog`} />
            </BoxTitle>
            <MonthYearBrowser selected={unit} setSelected={setUnit} date={activeDate} setDate={setActiveDate} />
            {unit === "YEAR" ? (
                <MonthLogSummaryList
                    busy={busyLoading}
                    list={monthList}
                    onClickMonth={changeActiveMonth}
                    onDownloadReview={handleDownloadReview}
                />
            ) : (
                <Row>
                    <Col span={24}>
                        {viewMode === "TABLE" ? (
                            <TimeTable
                                busy={busyLoading}
                                activeMonth={activeDate}
                                entries={entriesList}
                                onClickEntry={onClickEntry}
                                onClickHour={onClickHour}
                            />
                        ) : (
                            <TimeLogList busy={busyLoading} entries={entriesList} onClickEntry={onClickEntry} />
                        )}
                    </Col>
                </Row>
            )}

            {/* Control */}
            {unit === "MONTH" && (
                <React.Fragment>
                    {/* REVIEW */}
                    {monthSummary?.review ? (
                        <BoxContent border="top">
                            <Row gutter={["small", "small"]}>
                                <Col>
                                    <Image icon="user" rounded src={monthSummary?.review.reviewedByUser.picture?.url} />
                                </Col>
                                <Col grow align={["left", "center"]}>
                                    <Text lineHeight="small">
                                        <Text weight="semiBold">{monthSummary?.review.reviewedByUser.fullName}</Text>
                                        <br />
                                        <Text size="small" color="base60">
                                            {toRelativeDate(monthSummary?.review.created)}
                                        </Text>
                                    </Text>
                                </Col>
                                <Col align={["right", "center"]}>
                                    <Badge color="success">
                                        <FormattedMessage id={`${msgKey}.reviewed`} />
                                    </Badge>
                                </Col>
                                {onDeleteReview &&
                                    [monthSummary.review.reviewedByUser?.id, monthSummary.review.user?.id].includes(
                                        me?.user.id
                                    ) && (
                                        <Col align={["right", "center"]}>
                                            <ConfirmActionButton
                                                square
                                                popup
                                                size="small"
                                                icon="trash"
                                                iconColor="error"
                                                label={<FormattedMessage id={`${msgKey}.deleteReview`} />}
                                                question={<FormattedMessage id={`${msgKey}.deleteReviewConfirm`} />}
                                                onConfirm={() => handleDeleteReview(monthSummary.review)}
                                                busy={busyDeletingReview}
                                            />
                                        </Col>
                                    )}
                            </Row>
                        </BoxContent>
                    ) : null}

                    {/* ACTIONS */}
                    <BoxContent border="top">
                        <Row gutter={["tiny", "tiny"]}>
                            <Col grow align={["left", "center"]}>
                                {monthSummary?.review ? (
                                    <Button
                                        icon="download"
                                        iconColor="primary"
                                        onClick={() => handleDownloadReview(monthSummary?.review)}
                                    >
                                        <FormattedMessage id={`${msgKey}.downloadLogReport`} />
                                    </Button>
                                ) : onCreateReview ? (
                                    <Button
                                        icon="check"
                                        iconColor="success"
                                        onClick={onCreateReview}
                                        disabled={!entriesList?.length || monthsDiff(new Date(), activeDate) > 0}
                                    >
                                        <FormattedMessage id={`${msgKey}.approveMonth`} />
                                    </Button>
                                ) : onRegisterClick ? (
                                    <Button icon="clock" iconColor="primary" onClick={onRegisterClick}>
                                        <FormattedMessage id={`${msgKey}.register`} />
                                    </Button>
                                ) : activeDate.getMonth() < new Date().getMonth() &&
                                  activeDate.getFullYear() <= new Date().getFullYear() ? (
                                    <Badge color="error">
                                        <FormattedMessage id={`${msgKey}.notReviewed`} />
                                    </Badge>
                                ) : activeDate.getMonth() === new Date().getMonth() &&
                                  activeDate.getFullYear() === new Date().getFullYear() ? (
                                    <Badge color="silent">
                                        <FormattedMessage id={`${msgKey}.current`} />
                                    </Badge>
                                ) : (
                                    <span />
                                )}
                            </Col>
                            <Col shrink align={["right", "center"]}>
                                <ButtonGroup>
                                    <Button
                                        square
                                        icon="calendar"
                                        active={viewMode === "TABLE"}
                                        onClick={() => setViewMode("TABLE")}
                                    />
                                    <Button
                                        square
                                        icon="list"
                                        active={viewMode === "LIST"}
                                        onClick={() => setViewMode("LIST")}
                                    />
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </BoxContent>
                </React.Fragment>
            )}

            {/* Busy downloading review */}
            {busyDownloadingReview && (
                <PopupLoader
                    label={<FormattedMessage id={`${msgKey}.downloadingLog`} />}
                    description={<FormattedMessage id={`${msgKey}.downloadingLogDescription`} />}
                />
            )}
        </Box>
    );
};
