import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import Image from "../../media/Image";
import { getRuntimeCounterListByVessel } from "../../../api/runtime/runtimeCounter";
import { VesselContext } from "../../../routes/vessel/VesselRoot";
import { Text } from "../../typography/Text";

const msgKey = "runtimeCounterSelect";

export const RuntimeCounterSelect = ({ value, onChange, placeholder }) => {
    const { vessel } = useContext(VesselContext);
    const [counterList, setCounterList] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);

    useEffect(() => {
        setBusyLoading(true);
        getRuntimeCounterListByVessel(vessel.id).then((response) => {
            setCounterList(response);
            setBusyLoading(false);
        });
    }, [vessel.id]);

    return (
        <CustomSelect
            value={value}
            onChange={(val) => onChange(counterList.find((c) => c.id === val))}
            busy={busyLoading}
            placeholder={placeholder || <FormattedMessage id={`${msgKey}.selectCounter`} />}
            noOptionsMessage={() => <FormattedMessage id={`${msgKey}.noCounters`} />}
            isClearable={true}
            isSearchable={false}
        >
            {counterList?.map((counter) => (
                <OptionItem
                    key={counter.id}
                    value={counter.id}
                    symbol={<Image iconColor="primary" icon="timer" />}
                    label={counter.name}
                    append={
                        <Text color="base60" size="small">
                            <FormattedMessage id={`${msgKey}.hoursShort`} values={{ value: counter.hoursCurrent }} />
                        </Text>
                    }
                />
            ))}
        </CustomSelect>
    );
};
