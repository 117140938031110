import classNames from "classnames";
import React from "react";
import styles from "./styles.scss";

export const RadioGroup = ({ value, onChange, children, noMargin }) => {
    return (
        <div className={classNames(styles.group, { [styles.noMargin]: noMargin })}>
            {React.Children.map(children, (child) => {
                return React.cloneElement(child, {
                    groupValue: value,
                    onSelect: onChange
                });
            })}
        </div>
    );
};
