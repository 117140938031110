import React from "react";
import styles from "./TimeDisplay.scss";
import { Row } from "../../../grid/Row/Row";
import Col from "../../../grid/Col";
import moment from "moment";
import classNames from "classnames";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { dateRangeToHourValue } from "../../../../utils/dateUtils";

const msgKey = "timeRangePicker";

export const TimeDisplay = ({ start, end, selected, onClickTime }) => {
    return (
        <div className={styles.base}>
            <Row gutter={["tiny", "tiny"]}>
                <Col span={9}>
                    <div className={classNames(styles.dateBlock, { [styles.selected]: selected === "START" })}>
                        <div className={styles.label}>
                            <FormattedMessage id={`${msgKey}.startTime`} />
                        </div>
                        <div className={styles.time} onClick={() => onClickTime({ entry: "START", type: "TIME" })}>
                            {moment(start).format("HH:mm")}
                        </div>
                        <div className={styles.date} onClick={() => onClickTime({ entry: "START", type: "DATE" })}>
                            {moment(start).format("dddd, D. MMM")}
                        </div>
                    </div>
                </Col>
                <Col span={6} grow>
                    <div className={styles.hourBlock}>
                        <div
                            className={classNames(styles.hours, {
                                [styles.invalid]: start && end && dateRangeToHourValue(start, end) < 0.5
                            })}
                        >
                            {start && end ? <FormattedNumber value={dateRangeToHourValue(start, end)} /> : "0"}
                        </div>
                        <div className={styles.label}>
                            <FormattedMessage id={`${msgKey}.hours`} />
                        </div>
                    </div>
                </Col>
                <Col span={9}>
                    <div className={classNames(styles.dateBlock, { [styles.selected]: selected === "END" })}>
                        <div className={styles.label}>
                            <FormattedMessage id={`${msgKey}.endTime`} />
                        </div>
                        <div className={styles.time} onClick={() => onClickTime({ entry: "END", type: "TIME" })}>
                            {end ? moment(end).format("HH:mm") : "-"}
                        </div>
                        <div className={styles.date} onClick={() => onClickTime({ entry: "END", type: "DATE" })}>
                            {end ? moment(end).format("dddd, D. MMM") : "-"}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
