import React from "react";
import classNames from "classnames";
import styles from "./Badge.scss";

import { Icon } from "../../graphic/Icon";

export const Badge = ({
    color,
    customColor,
    fill,
    number,
    size,
    leftSpacing,
    rightSpacing,
    className,
    icon,
    iconColor,
    dot,
    stripped,
    spacing,
    onClick,
    placement,
    children
}) => {
    return (
        <span
            className={classNames(
                styles.base,
                {
                    [styles.primary]: color === "primary",
                    [styles.silent]: color === "silent",
                    [styles.info]: color === "info",
                    [styles.success]: color === "success",
                    [styles.warning]: color === "warning",
                    [styles.error]: color === "error",
                    [styles.contrast]: color === "contrast",
                    [styles.rankLow]: color === "rank-low",
                    [styles.rankMid]: color === "rank-mid",
                    [styles.rankHigh]: color === "rank-high",
                    [styles.attention]: color === "attention",

                    [styles.iconError]: iconColor === "error",
                    [styles.iconInfo]: iconColor === "info",

                    [styles.number]: number,

                    [styles.small]: size === "small",
                    [styles.large]: size === "large",

                    [styles.leftSpacing]: leftSpacing,
                    [styles.rightSpacing]: rightSpacing,
                    [styles.listSpacing]: spacing === "list",

                    [styles.fill]: fill,
                    [styles.stripped]: stripped,
                    [styles.clickable]: !!onClick,

                    [styles.placeTopRight]: placement === "top-right"
                },
                className
            )}
            onClick={onClick}
            style={
                customColor && fill
                    ? { backgroundColor: customColor, borderColor: customColor }
                    : customColor && !fill
                    ? {
                          borderColor: customColor,
                          color: customColor
                      }
                    : null
            }
        >
            {dot ? <span className={styles.dot} style={{ backgroundColor: dot }} /> : null}
            {icon ? <Icon name={icon} className={styles.icon} /> : null}
            {children !== undefined ? <span className={styles.label}>{children}</span> : null}
        </span>
    );
};
