import React from "react";
import ReactDOM from "react-dom/client";
import { AppEntry } from "./AppEntry";

// Create root element
const rootElement = document.createElement("div");
rootElement.id = "app";
document.body.appendChild(rootElement);

ReactDOM.createRoot(document.getElementById("app")).render(<AppEntry />);
