import moment from "moment";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { USER_LOG_ENTRY_TYPES } from "../../../../api/constants";
import { Icon } from "../../../graphic/Icon";
import { ScrollableArea } from "../../../layout/ScrollableArea";
import { Item, ItemList } from "../../../list/ItemList";
import Badge from "../../../misc/Badge/Badge";
import { TitleDescriptionText } from "../../../typography/TitleDescriptionText";
import styles from "./TimeLogList.scss";
import { dateRangeToHourValue } from "../../../../utils/dateUtils";

const msgKey = "timeLogList";

export const TimeLogList = ({ busy, entries, onClickEntry }) => {
    return (
        <div className={styles.base}>
            <ScrollableArea>
                <div className={styles.content}>
                    <ItemList
                        busy={busy}
                        onItemClick={(val) => onClickEntry(val)}
                        emptyMessage={<FormattedMessage id={`${msgKey}.noRegisteredHours`} />}
                    >
                        {entries?.map((entry) => (
                            <Item
                                key={entry.id}
                                value={entry}
                                append={
                                    <Badge fill customColor={USER_LOG_ENTRY_TYPES[entry.type].color}>
                                        <FormattedMessage id={USER_LOG_ENTRY_TYPES[entry.type].label} />
                                    </Badge>
                                }
                            >
                                <TitleDescriptionText
                                    title={
                                        <>
                                            <FormattedNumber
                                                value={dateRangeToHourValue(
                                                    new Date(entry.startTime),
                                                    new Date(entry.endTime)
                                                )}
                                            />{" "}
                                            <FormattedMessage id={`${msgKey}.hours`} />{" "}
                                            {entry.remarks ? <Icon name="comment" size="small" color="base40" /> : null}
                                        </>
                                    }
                                    description={`${moment(entry.startTime).format("LLL")} - ${moment(
                                        entry.endTime
                                    ).format("LLL")}`}
                                />
                            </Item>
                        ))}
                    </ItemList>
                </div>
            </ScrollableArea>
        </div>
    );
};
