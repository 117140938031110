import React from "react";
import styles from "./ContentGroup.scss";

export const ContentGroup = ({ title, children, counter }) => {
    return (
        <div className={styles.contentGroup}>
            {title && (
                <div className={styles.title}>
                    <div className={styles.indicator} />
                    <div className={styles.text}>{title}</div>
                    {counter && parseInt(counter, 10) > 1 ? <div className={styles.counter}>{counter}</div> : null}
                </div>
            )}
            <div className={styles.content}>{children}</div>
        </div>
    );
};
