import React from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Radio } from "../../form/Radio";

export const Item = ({ value, onChange, checked, groupValue, symbol, append, children }) => {
    return (
        <div
            className={classNames(style.item, {
                [style.checked]: checked
            })}
            onClick={() => onChange(value)}
        >
            <Radio value={value} groupValue={groupValue} onSelect={onChange}>
                {children}
                {symbol ? (
                    <div className={style.symbol}>
                        <div className={style.wrapper}>{symbol}</div>
                    </div>
                ) : null}
            </Radio>

            {append ? <div className={style.append}>{append}</div> : null}
        </div>
    );
};
