import moment from "moment";
import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { getDeviationKey, getStatusColor } from "../../../utils/deviationUtils";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import Image from "../../media/Image";
import Badge from "../../misc/Badge";
import { Spinner } from "../../loaders/Spinner";
import { Text } from "../../typography/Text";
import styles from "./DeviationItemList.scss";
import { HighlightText } from "../../typography/HighlightText";
import { truncateText } from "../../../utils/stringUtils";
import { toRelativeDateTime } from "../../../utils/dateUtils";

const msgKey = "deviationItemList";

export const DeviationItemList = ({
    busy,
    deviationList,
    onItemClick,
    showStatus = false,
    showCategory = true,
    showVessel = false,
    searchTerm
}) => {
    return (
        <div className={styles.base}>
            {busy && !deviationList?.length ? (
                <div className={styles.loader}>
                    <Spinner />
                </div>
            ) : deviationList?.length ? (
                deviationList?.map((deviation) => (
                    <div
                        key={deviation.id}
                        className={classNames(styles.item, {
                            [styles.attention]:
                                moment
                                    .duration(moment().diff(moment(deviation.updated || deviation.registered)))
                                    .asSeconds() < 60
                        })}
                        onClick={() => onItemClick(deviation)}
                    >
                        <div className={styles.wrapper}>
                            <div className={styles.info}>
                                <div className={styles.ident}>
                                    <HighlightText term={searchTerm} text={getDeviationKey(deviation)} />
                                </div>
                            </div>
                            <Row gutter={["small", "small"]}>
                                <Col grow span={16}>
                                    <Text block lineThrough={deviation.status === "CLOSED"}>
                                        <HighlightText
                                            term={searchTerm}
                                            text={truncateText(deviation.description, 180)}
                                        />
                                    </Text>
                                    <Text size="small" color="base60">
                                        {deviation.anonymous ? (
                                            <FormattedMessage
                                                id={`${msgKey}.registeredByAnonymousSummary`}
                                                values={{
                                                    time: toRelativeDateTime(deviation.registered)
                                                }}
                                            />
                                        ) : (
                                            <FormattedMessage
                                                id={`${msgKey}.registeredByUserSummary`}
                                                values={{
                                                    name: deviation.registeredByUser.fullName,
                                                    time: toRelativeDateTime(deviation.registered)
                                                }}
                                            />
                                        )}
                                    </Text>
                                </Col>
                                {deviation.files?.[0] && (
                                    <Col>
                                        <Image
                                            icon="deviation"
                                            size="medium"
                                            grayscale={deviation.status === "CLOSED"}
                                            src={deviation.files[0]?.url}
                                        />
                                    </Col>
                                )}
                                <Col span={24}>
                                    <DeviationBadges
                                        deviation={deviation}
                                        showStatus={showStatus}
                                        showCategory={showCategory}
                                        showVessel={showVessel}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </div>
                ))
            ) : (
                <div className={styles.empty}>
                    <FormattedMessage id={`${msgKey}.noDeviations`} />
                </div>
            )}
        </div>
    );
};

export const DeviationBadges = ({ deviation, showStatus, showCategory, showVessel }) => {
    return (
        <Row gutter={["tiny", "tiny"]}>
            {showStatus ? (
                <Col>
                    <Badge {...getStatusColor(deviation.status)}>
                        <FormattedMessage id={`deviationStatus.${deviation.status}`} />
                    </Badge>
                </Col>
            ) : null}
            {deviation.priority ? (
                <Col>
                    <Badge
                        fill={deviation.priority > 0}
                        icon={`priority-${deviation.priority > 0 ? "up" : "down"}-${Math.abs(deviation.priority)}`}
                        iconColor={deviation.priority < 0 ? "info" : null}
                        color={deviation.priority > 1 ? "rank-high" : deviation.priority > 0 ? "rank-mid" : null}
                    >
                        <FormattedMessage id={`deviationPriority.value_${deviation.priority}`} />
                    </Badge>
                </Col>
            ) : null}
            {showCategory && deviation.category ? (
                <Col>
                    <Badge dot={deviation.category.color}>
                        {deviation.category.name}
                        {deviation.category.sensitive ? "*" : null}
                    </Badge>
                </Col>
            ) : null}
            {showVessel ? (
                <Col>
                    <Badge icon="vessel">{deviation.vessel.name}</Badge>
                </Col>
            ) : null}
            {deviation.assignedToUser ? (
                <Col>
                    <Badge icon="user">{deviation.assignedToUser.fullName}</Badge>
                </Col>
            ) : null}
        </Row>
    );
};
