import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getChecklistList } from "../../../api/vessel/checklist/checklist";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import Image from "../../media/Image";
import { Text } from "../../typography/Text";

const msgKey = "checklistSelect";

export const ChecklistSelect = ({ vessel, value, placeholder, onChange }) => {
    const [checklists, setChecklists] = useState(null);
    const [busyLoading, setBusyLoading] = useState(false);

    useEffect(() => {
        setBusyLoading(true);
        getChecklistList(vessel.id, { archived: false }).then((response) => {
            setChecklists(response);
            setBusyLoading(false);
        });
    }, [vessel.id]);

    return (
        <CustomSelect
            value={value}
            onChange={(val) => onChange(checklists.find((entry) => entry.checklist.id === val)?.checklist)}
            placeholder={placeholder}
            busy={busyLoading}
            isClearable={true}
            isSearchable={false}
        >
            <OptionItem
                symbol={<Image size="small" iconColor="success" icon="plus" />}
                label={<FormattedMessage id={`${msgKey}.createChecklist`} />}
                link={`/vessels/${vessel.id}/checklists`}
            />

            {checklists?.map((entry) => (
                <OptionItem
                    key={entry.checklist.id}
                    value={entry.checklist.id}
                    symbol={<Image size="small" iconColor="primary" icon="list" />}
                    label={
                        value === entry.checklist.id ? (
                            entry.checklist.title || <FormattedMessage id={`${msgKey}.untitled`} />
                        ) : (
                            <>
                                <Text block>
                                    {entry.checklist.title || <FormattedMessage id={`${msgKey}.untitled`} />}
                                </Text>
                                {entry.checklist.description ? (
                                    <Text size="small" color="base40">
                                        {entry.checklist.description.substring(0, 200)}
                                    </Text>
                                ) : null}
                            </>
                        )
                    }
                />
            ))}
        </CustomSelect>
    );
};
