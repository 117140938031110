import React from "react";
import style from "./style.scss";

import { Badge } from "../../misc/Badge";
import { FormattedMessage } from "react-intl";

const msgKey = "invoiceStatusBadge";

export default class InvoiceStatusBadge extends React.Component {
    render() {
        // Properties
        const { status } = this.props;
        if (!status) {
            return null;
        }

        let label;
        let color;

        switch (status.toUpperCase()) {
            case "DRAFT":
                label = "draft";
                color = "warning";
                break;
            case "OPEN":
                label = "open";
                color = "error";
                break;
            case "PAID":
                label = "paid";
                color = "success";
                break;
            case "VOID":
                label = "void";
                color = null;
                break;
            case "UNCOLLECTIBLE":
                label = "uncollectible";
                color = null;
                break;
            default:
                label = null;
                color = null;
        }

        return label ? (
            <Badge className={style.base} color={color}>
                <FormattedMessage id={`${msgKey}.${label}`} />
            </Badge>
        ) : null;
    }
}
