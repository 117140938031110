import React from "react";
import classNames from "classnames";
import styles from "./PopupTitle.scss";
import { Icon } from "../../graphic/Icon";

export const PopupTitle = ({ icon, subtitle, size, align, type, children }) => {
    return (
        <div
            className={classNames(styles.base, {
                [styles.typeError]: type === "error",
                [styles.sizeMedium]: size === "medium",
                [styles.alignCenter]: align === "center"
            })}
        >
            {icon ? (
                <div className={styles.icon}>
                    <Icon name={icon} />
                </div>
            ) : null}
            <div className={styles.titles}>
                <div className={styles.title}>{children}</div>
                {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
            </div>
        </div>
    );
};
