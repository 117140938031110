import classNames from "classnames";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../../graphic/Icon";
import Badge from "../../misc/Badge";
import { Text } from "../../typography/Text";
import style from "./style.scss";

const msgKey = "pricePlan";

export const PricePlan = ({
    title,

    price,
    hidePrice,
    currency,
    addTax,
    discount,
    savePercent,
    interval,

    features,
    included,
    showIncluded,
    setShowIncluded,
    limitations,

    status,
    variant,
    children
}) => {
    return (
        <div
            className={classNames(style.base, {
                [style.variantSilent]: variant === "silent",
                [style.variantHighlighted]: variant === "highlighted"
            })}
        >
            {/* HEADER */}
            <Header
                status={status}
                title={title}
                price={price}
                savePercent={savePercent}
                discount={discount}
                hidePrice={hidePrice}
                currency={currency}
                addTax={addTax}
                interval={interval}
            />

            {/* Features */}
            <FeatureList features={features} />

            {/* Includes */}
            <IncludedList included={included} showIncluded={showIncluded} setShowIncluded={setShowIncluded} />

            {/* Limitations */}
            <Limitations limitations={limitations} />

            {/* ACTION */}
            <div className={style.action}>{children}</div>
        </div>
    );
};

const Header = ({ status, title, price, savePercent, discount, hidePrice, currency, addTax, interval }) => {
    let priceTens, priceDecimals;
    if (price) {
        priceTens = Math.floor(price);
        priceDecimals = Number.isInteger(price) ? null : String((price % 1).toFixed(2)).slice(2);
    }
    return (
        <div className={style.header}>
            {/* Status */}
            {status === "canceled" ? (
                <div className={style.status}>
                    <div className={classNames(style.statusBadge, style[status])}>
                        <FormattedMessage id={`${msgKey}.canceled`} />
                    </div>
                </div>
            ) : null}

            {/* Title*/}
            <div className={style.title}>{title}</div>

            {/* Save percent */}
            {savePercent ? (
                <div className={style.cornerBadge}>
                    <Badge color="success">
                        <FormattedMessage id={`${msgKey}.savePercent`} values={{ percent: savePercent }} />
                    </Badge>
                </div>
            ) : discount ? (
                <div className={style.cornerBadge}>
                    <Badge color="info">
                        <FormattedMessage id={`${msgKey}.discount`} values={{ percent: discount }} />
                    </Badge>
                </div>
            ) : null}

            {/* Pricing */}
            {hidePrice ? (
                <div className={style.pricing}>
                    <div className={style.contact}>
                        <FormattedMessage id={`${msgKey}.contactUs`} />
                    </div>
                    <div className={style.description}>
                        <FormattedMessage id={`${msgKey}.contactUsDescription`} />
                    </div>
                </div>
            ) : (
                <div className={style.pricing}>
                    <div className={style.price}>
                        <div className={style.amount}>
                            <CurrencyPrefix currency={currency} />
                            {priceTens}
                            {priceDecimals && priceDecimals !== "00" ? (
                                <div className={style.decimals}>.{priceDecimals}</div>
                            ) : null}
                        </div>
                        <CurrencySuffix currency={currency} />
                        {addTax ? (
                            <div className={style.vat}>
                                <FormattedMessage id={`${msgKey}.exVat`} />
                            </div>
                        ) : null}
                    </div>
                    <div className={style.description}>
                        {interval === "MONTH" ? (
                            <FormattedMessage id={`${msgKey}.vesselPerMonth`} />
                        ) : (
                            <FormattedMessage id={`${msgKey}.vesselPerYear`} />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

const FeatureList = ({ features }) => {
    const { userCountTo, orgModule, supportLevel, paymentMethods } = features || {};
    return (
        <ul className={style.features}>
            {/* Users */}
            {userCountTo ? (
                <FeatureEntry>
                    {userCountTo >= 100 ? (
                        <FormattedMessage id={`${msgKey}.userCountUnlimited`} />
                    ) : (
                        <FormattedMessage
                            id={`${msgKey}.userCountTo`}
                            values={{ value: userCountTo, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    )}
                </FeatureEntry>
            ) : null}

            {/* Org module */}
            {orgModule ? (
                <FeatureEntry>
                    <FormattedMessage id={`${msgKey}.orgModule`} />
                </FeatureEntry>
            ) : null}

            {/* Support */}
            {supportLevel ? (
                <FeatureEntry>
                    {supportLevel === 1 ? (
                        <FormattedMessage id={`${msgKey}.limitedSupport`} />
                    ) : supportLevel === 2 ? (
                        <FormattedMessage id={`${msgKey}.standardSupport`} />
                    ) : (
                        <FormattedMessage id={`${msgKey}.prioritySupport`} />
                    )}
                </FeatureEntry>
            ) : null}

            {/* Payment methods */}
            {paymentMethods ? (
                <FeatureEntry>
                    {paymentMethods.includes("card") ? (
                        <Text block>
                            <FormattedMessage id={`${msgKey}.paymentWithCard`} />
                        </Text>
                    ) : paymentMethods.includes("all") ? (
                        <Text block>
                            <FormattedMessage id={`${msgKey}.paymentWithCardOrInvoice`} />
                        </Text>
                    ) : null}
                </FeatureEntry>
            ) : null}
        </ul>
    );
};

const IncludedList = ({ included, showIncluded, setShowIncluded }) => {
    const { maintenanceItemsQuota, checklistsQuota, coursesQuota, deviationsQuota } = included || {};
    return Object.keys(included || {}).length > 0 ? (
        <div
            className={classNames(style.included, {
                [style.visible]: showIncluded
            })}
        >
            <div className={style.title} onClick={() => setShowIncluded(!showIncluded)}>
                <FormattedMessage id={`${msgKey}.includes`} />
                <div className={style.icon}>
                    <Icon name={showIncluded ? "chevron-up" : "chevron-down"} />
                </div>
                <div className={style.counter}>+{Object.keys(included).length}</div>
            </div>
            <ul>
                {/* Storage */}
                {/* {storageMbQuota ? (
                    <li>
                        <FormattedMessage
                            id={`${msgKey}.storageMbQuota`}
                            values={{
                                value: storageMbQuota >= 1000 ? Math.round(storageMbQuota / 1000) : storageMbQuota,
                                unit: storageMbQuota >= 1000 ? "GB" : "MB",
                                strong: (msg) => <strong>{msg}</strong>
                            }}
                        />
                    </li>
                ) : null} */}

                {/* Maintenance items */}
                {maintenanceItemsQuota ? (
                    <li>
                        <FormattedMessage
                            id={
                                maintenanceItemsQuota >= 1000
                                    ? `${msgKey}.maintenanceItemsQuotaUnlimited`
                                    : `${msgKey}.maintenanceItemsQuota`
                            }
                            values={{ value: maintenanceItemsQuota, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </li>
                ) : null}

                {/* Checklists */}
                {checklistsQuota ? (
                    <li>
                        <FormattedMessage
                            id={
                                checklistsQuota >= 100
                                    ? `${msgKey}.checklistsQuotaUnlimited`
                                    : `${msgKey}.checklistsQuota`
                            }
                            values={{ value: checklistsQuota, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </li>
                ) : null}

                {/* Courses */}
                {coursesQuota ? (
                    <li>
                        <FormattedMessage
                            id={coursesQuota >= 100 ? `${msgKey}.coursesQuotaUnlimited` : `${msgKey}.coursesQuota`}
                            values={{ value: coursesQuota, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </li>
                ) : null}

                {/* Deviations */}
                {deviationsQuota ? (
                    <li>
                        <FormattedMessage
                            id={
                                deviationsQuota >= 1000
                                    ? `${msgKey}.deviationsQuotaUnlimited`
                                    : `${msgKey}.deviationsQuota`
                            }
                            values={{ value: deviationsQuota, strong: (msg) => <strong>{msg}</strong> }}
                        />
                    </li>
                ) : null}
            </ul>
        </div>
    ) : null;
};

const Limitations = ({ limitations }) => {
    const { vesselLengthBelow, vesselGrossTonnageBelow } = limitations || {};
    return limitations ? (
        <div className={style.limitations}>
            {vesselLengthBelow ? (
                <FormattedMessage
                    id={`${msgKey}.vesselLimitBelowXMeters`}
                    values={{
                        value: vesselLengthBelow,
                        strong: (msg) => <strong>{msg}</strong>
                    }}
                />
            ) : vesselGrossTonnageBelow ? (
                <FormattedMessage
                    id={`${msgKey}.vesselLimitBelowXTonnage`}
                    values={{
                        value: vesselGrossTonnageBelow,
                        strong: (msg) => <strong>{msg}</strong>
                    }}
                />
            ) : (
                <FormattedMessage id={`${msgKey}.noVesselLimitations`} />
            )}
        </div>
    ) : null;
};

const FeatureEntry = ({ children }) => {
    return (
        <li>
            <div className={style.symbol}>
                <Icon name={"check-circle"} />
            </div>
            <div className={style.text}>{children}</div>
        </li>
    );
};

const CurrencyPrefix = ({ currency }) => {
    if (currency === "usd") {
        return <span>&#36;</span>;
    }
    return null;
};

const CurrencySuffix = ({ currency }) => {
    if (currency === "usd") {
        return null;
    }
    return <span className={style.suffix}>{currency?.toUpperCase()}</span>;
};
