import React from "react";
import style from "./style.scss";

import { FormattedMessage } from "react-intl";
import { Word } from "../../lang/Word";

export default class Option extends React.Component {
    render() {
        let element = (
            <option value={this.props.value} className={style.option}>
                {this.props.children}
            </option>
        );

        const child = this.props.children;
        if (child && (child.type === FormattedMessage || child.type === Word)) {
            element = React.cloneElement(child, {
                children: (message) => {
                    return (
                        <option value={this.props.value} className={style.option}>
                            {message}
                        </option>
                    );
                }
            });
        }

        return element;
    }
}
