import React from "react";
import styles from "./Curtain.scss";
import { ShieldLogo } from "../../graphic/ShieldLogo";

export const Curtain = ({ label }) => {
    return (
        <div className={styles.base}>
            <div className={styles.content}>
                <div className={styles.logo}>
                    <ShieldLogo variant="plain" />
                </div>
                <div className={styles.dots}>
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                    <div className={styles.dot} />
                </div>
            </div>
            {label && <div className={styles.label}>{label}</div>}
        </div>
    );
};
