import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import style from "./style.scss";
import { iconList } from "./iconList";

/**
 * APP ICON
 *
 * Using this tool to convert icons:
 * https://react-svg-icon-live-generator.herokuapp.com/
 *
 * or this to clean it:
 * https://jakearchibald.github.io/svgomg/
 */

export const Icon = ({ name, color, size, rotate, filled, circle, marginRight, presentation, className }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <span
            className={classNames(
                style.base,
                {
                    [style.presentation]: presentation,

                    [style.colorBase]: color === "base",
                    [style.colorBase80]: color === "base80",
                    [style.colorBase60]: color === "base60",
                    [style.colorBase40]: color === "base40",

                    [style.colorInfo]: color === "info",
                    [style.colorPrimary]: color === "primary",
                    [style.colorSuccess]: color === "success",
                    [style.colorWarning]: color === "warning",
                    [style.colorError]: color === "error",
                    [style.colorContrast]: color === "contrast",

                    [style.sizeSmall]: size === "small",
                    [style.sizeMedium]: size === "medium",
                    [style.sizeLarge]: size === "large",
                    [style.sizeXLarge]: size === "xLarge",
                    [style.sizeFill]: size === "fill",

                    [style.marginRight]: marginRight,

                    [style.rotate]: rotate,
                    [style.filled]: filled,
                    [style.circle]: circle
                },
                className
            )}
            style={{ display: loaded ? "inline-block" : "none" }}
        >
            {iconList[name]?.element}
            {presentation ? <span className={style.label}>{name}</span> : null}
        </span>
    );
};

Icon.propTypes = {
    name: PropTypes.oneOf(Object.keys(iconList)).isRequired
};
