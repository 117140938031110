import moment from "moment";
import React, { useEffect, useState } from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { USER_LOG_ENTRY_TYPES } from "../../../api/constants";
import { Alert } from "../../alert/Alert";
import Input from "../../form/Input";
import Select, { Option } from "../../form/Select";
import { Icon } from "../../graphic/Icon";
import Col from "../../grid/Col";
import { Row } from "../../grid/Row/Row";
import { Popup, PopupContent, PopupTitle } from "../../notify/Popup";
import { VesselSelect } from "../../selects/VesselSelect/VesselSelect";
import { SingleDatePicker } from "../SingleDatePicker";
import { TimeDisplay } from "./parts/TimeDisplay";
import { Wheel } from "./parts/Wheel";
import { TimePicker } from "../../form/TimePicker";
import { Button } from "../../button/Button";
import { dateRangeToHourValue } from "../../../utils/dateUtils";
const msgKey = "timeRangePicker";

export const TimeRangePicker = ({ onRegister, onClose, busy, error, fromTime }) => {
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [remarks, setRemarks] = useState(null);
    const [selected, setSelected] = useState("START");
    const [showPrompt, setShowPrompt] = useState(null);
    const [hours, setHours] = useState(0);
    const [type, setType] = useState(USER_LOG_ENTRY_TYPES["REST"].value);
    const [vesselId, setVesselId] = useState(null);

    useEffect(() => {
        const start = moment(fromTime || new Date());
        const remainder = start.minutes() > 0 ? 30 - (start.minute() % 30) : 0;
        setStartTime(new Date(moment(start).add(remainder, "minutes")));
    }, [fromTime]);

    useEffect(() => {
        setHours(startTime && endTime ? dateRangeToHourValue(startTime, endTime) : 0);
    }, [startTime, endTime]);

    const handleChange = (step) => {
        if (selected === "START") {
            setStartTime(new Date(moment(startTime).add(step > 0 ? 30 : -30, "minutes")));
        } else if (selected === "END") {
            setEndTime(new Date(moment(endTime).add(step > 0 ? 30 : -30, "minutes")));
        }
    };

    const handleSelectTime = (value) => {
        if (value === "START") {
            setSelected("START");
        } else {
            if (!endTime) {
                setEndTime(new Date(moment(startTime).add(6, "hours")));
            }
            setSelected("END");
        }
    };

    return (
        <React.Fragment>
            <Popup onClose={onClose}>
                <PopupTitle subtitle={<FormattedMessage id={`${msgKey}.description`} />}>
                    <FormattedMessage id={`${msgKey}.timeLog`} />
                </PopupTitle>
                <PopupContent>
                    <Row>
                        <Col span={24}>
                            <Row gutter={["small", "small"]}>
                                <Col span={24}>
                                    <TimeDisplay
                                        selected={selected}
                                        onClickTime={({ entry, type }) => handleSelectTime(entry) & setShowPrompt(type)}
                                        start={startTime}
                                        end={endTime}
                                    />
                                </Col>
                                <Col span={24}>
                                    <Wheel
                                        onClick={() => handleSelectTime(selected === "START" ? "END" : "START")}
                                        onChange={handleChange}
                                        label={
                                            <>
                                                <Icon name="switch" size="xLarge" color="contrast" />
                                            </>
                                        }
                                    />
                                </Col>
                                {error && (
                                    <Col span={24} align={["center", "top"]}>
                                        <div style={{ width: "100%" }}>
                                            <Alert type="error">{error}</Alert>
                                        </div>
                                    </Col>
                                )}

                                <Col span={24}>
                                    <Row gutter={["tiny", "tiny"]}>
                                        <Col span={24}>
                                            <Input
                                                value={remarks}
                                                onChange={(e) => setRemarks(e.target.value)}
                                                placeholder={<FormattedMessage id={`${msgKey}.remarks`} />}
                                            />
                                        </Col>
                                        <Col span={9}>
                                            <Select value={type} onChange={setType}>
                                                {Object.keys(USER_LOG_ENTRY_TYPES).map((key) => (
                                                    <Option key={key} value={USER_LOG_ENTRY_TYPES[key].value}>
                                                        <FormattedMessage id={USER_LOG_ENTRY_TYPES[key].label} />
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Col>
                                        <Col span={15}>
                                            <VesselSelect
                                                isCrewMember
                                                value={vesselId}
                                                onChange={setVesselId}
                                                menuPlacement="top"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Button
                                        type="submit"
                                        busy={busy}
                                        fill
                                        color="primary"
                                        disabled={hours <= 0 || hours > 168 || !vesselId}
                                        onClick={() => onRegister({ startTime, endTime, type, remarks, vesselId })}
                                    >
                                        {startTime && endTime ? (
                                            <FormattedMessage
                                                id={`${msgKey}.confirmHours`}
                                                values={{ hours: <FormattedNumber value={hours} /> }}
                                            />
                                        ) : (
                                            <FormattedMessage id={`${msgKey}.spinWheel`} />
                                        )}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </PopupContent>
            </Popup>

            {/* SET DATE */}
            {showPrompt === "DATE" && (
                <SingleDatePicker
                    opened={true}
                    value={selected === "START" ? startTime : endTime}
                    onChange={(val) =>
                        (selected === "START" ? setStartTime : setEndTime)(val ? new Date(val) : new Date()) &
                        setShowPrompt(null)
                    }
                    onClose={() => setShowPrompt(null)}
                />
            )}

            {/* SET TIME */}
            {showPrompt === "TIME" && (
                <TimePicker
                    step={30}
                    value={selected === "START" ? startTime : endTime}
                    onChange={(val) => (selected === "START" ? setStartTime(val) : setEndTime(val))}
                    onClose={() => setShowPrompt(null)}
                />
            )}
        </React.Fragment>
    );
};
