import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../../button/Button";
import Input from "../../form/Input";
import { Icon } from "../../graphic/Icon";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { Popup, PopupFooter, PopupTitle } from "../../notify/Popup";
import { DayPicker } from "./parts/DayPicker";

const msgKey = "singleDatePicker";

export const SingleDatePicker = ({ value, placeholder, error, onChange, opened, disabledDates, onClose }) => {
    const [editValue, setEditValue] = useState(value);
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setEditValue(value);
    }, [value]);

    const handleConfirm = () => {
        onChange && onChange(editValue ? new Date(editValue) : null);
        setEditValue(null);
        setFocused(false);
    };

    const handleInputClick = () => {
        document.activeElement.blur();
        setFocused(true);
    };

    const handleClearDate = () => {
        setEditValue(null);
        setFocused(false);
        onChange && onChange(null);
    };

    const handleSetToday = () => {
        onChange && onChange(new Date());
    };

    return (
        <React.Fragment>
            {!opened && (
                <Input
                    placeholder={placeholder || <FormattedMessage id={`${msgKey}.selectDate`} />}
                    error={error}
                    prepend={<Icon name="calendar" />}
                    value={value && moment(value).format("LL")}
                    onClick={handleInputClick}
                />
            )}

            {opened || focused ? (
                <Popup onClose={() => (onClose ? onClose() : setFocused(false))}>
                    <PopupTitle subtitle={<FormattedMessage id={`${msgKey}.description`} />}>
                        {editValue ? moment(editValue).format("LL") : <FormattedMessage id={`${msgKey}.selectDate`} />}
                    </PopupTitle>
                    <DayPicker value={editValue} onChange={setEditValue} disabledDates={disabledDates} />
                    <PopupFooter>
                        <Row gutter={["small", "small"]}>
                            <Col span={12}>
                                <Button fill size="small" icon="calendar" iconColor="primary" onClick={handleSetToday}>
                                    <FormattedMessage id={`${msgKey}.setToday`} />
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button fill size="small" icon="close" iconColor="primary" onClick={handleClearDate}>
                                    <FormattedMessage id={`${msgKey}.clearDate`} />
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Button fill color="primary" onClick={handleConfirm}>
                                    <FormattedMessage id={`${msgKey}.confirmDate`} />
                                </Button>
                            </Col>
                        </Row>
                    </PopupFooter>
                </Popup>
            ) : null}
        </React.Fragment>
    );
};

SingleDatePicker.propTypes = {
    value: PropTypes.instanceOf(Date)
};
