import React from "react";
import classNames from "classnames";
import styles from "./PaymentCard.scss";

import unknownCard from "../../../assets/images/cards/unknown.png";
import visaImage from "../../../assets/images/cards/visa.png";
import mastercardImage from "../../../assets/images/cards/mastercard.png";
import americanExpressImage from "../../../assets/images/cards/american-express.png";

export const PaymentCard = ({ brand, silent }) => {
    // Image source
    let imageSource = null;
    switch (brand.toUpperCase()) {
        case "VISA":
            imageSource = visaImage;
            break;
        case "MASTERCARD":
            imageSource = mastercardImage;
            break;
        case "AMEX":
            imageSource = americanExpressImage;
            break;
        default:
            imageSource = unknownCard;
    }

    return (
        <div
            className={classNames(styles.base, {
                [styles.silent]: silent
            })}
        >
            <img alt="card" className={styles.image} src={imageSource} />
        </div>
    );
};
