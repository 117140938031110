import React from "react";
import { FormattedMessage } from "react-intl";
import { Badge } from "../../misc/Badge";
import { CustomSelect, OptionItem } from "../../form/CustomSelect";
import { INVENTORY_ITEM_ACTIONS } from "../../../api/constants";

const msgKey = "inventoryItemTaskActionSelect";

export const InventoryItemTaskActionSelect = ({ value, onChange }) => {
    return (
        <CustomSelect value={value} onChange={onChange} placeholder={<FormattedMessage id={`${msgKey}.placeholder`} />}>
            {INVENTORY_ITEM_ACTIONS.map((type) => (
                <OptionItem
                    key={type}
                    value={type}
                    symbol={
                        <Badge>
                            <FormattedMessage id={`itemActions.${type}`} />
                        </Badge>
                    }
                />
            ))}
        </CustomSelect>
    );
};
