import React from "react";
import { FormattedMessage } from "react-intl";
import { ShieldLogo } from "../../graphic/ShieldLogo/ShieldLogo";
import Col from "../../grid/Col";
import Row from "../../grid/Row";
import { PageMessage } from "../../page/PageMessage";
import { Text } from "../../typography/Text";
import { Button } from "../../button/Button";

const msgKey = "manualInvoiceMessage";

export const ManualInvoiceMessage = ({ days }) => {
    return (
        <PageMessage title={<FormattedMessage id={`${msgKey}.title`} />} figure={<ShieldLogo size={100} />}>
            <Row gutter={["large", "large"]}>
                <Col span={24}>
                    <Text size="xlarge" weight="semiBold" color={days > 0 ? "success" : "error"}>
                        <FormattedMessage id={`${msgKey}.daysLeft`} values={{ days: Math.max(0, days) }} />
                    </Text>
                    <Text block size="medium" color="base80">
                        <FormattedMessage id={`${msgKey}.message`} />
                    </Text>
                </Col>
                <Col span={24}>
                    <Button link={`/me/help`} size="small">
                        {days < 0 ? (
                            <FormattedMessage id={`${msgKey}.contactSupport`} />
                        ) : (
                            <FormattedMessage id={`${msgKey}.cancelSubscription`} />
                        )}
                    </Button>
                </Col>
            </Row>
        </PageMessage>
    );
};
