import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import style from "./style.scss";
import { figureList } from "./figureList";

/**
 * FIGURE
 *
 * Using this tool to convert icons:
 * https://react-svg-icon-live-generator.herokuapp.com/
 *
 * or this to clean it:
 * https://jakearchibald.github.io/svgomg/
 */

export const Figure = ({ name, color, size, className }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return React.cloneElement(figureList[name]?.element, {
        className: classNames(
            style.base,
            {
                [style.colorPrimary]: color === "primary",
                [style.sizeRegular]: size === "regular",
                [style.sizeMedium]: size === "medium",
                [style.sizeLarge]: size === "large"
            },
            className
        ),
        style: { display: loaded ? "inline-block" : "none" }
    });
};

Figure.propTypes = {
    name: PropTypes.oneOf(Object.keys(figureList)).isRequired
};
