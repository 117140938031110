import React from "react";
import style from "./style.scss";

import PropTypes from "prop-types";
import { SingleDatePicker } from "../../time/SingleDatePicker";
import { TimePickerInput } from "../TimePicker/TimePickerInput";

export const DateTimePicker = ({ value, onChange, error, disabledDates, datePlaceholder, timePlaceholder }) => {
    const handleChangeDate = (editedValue) => {
        if (!onChange) return;
        if (editedValue && value) {
            const editedDateValue = new Date(editedValue);
            editedDateValue.setHours(value.getHours());
            editedDateValue.setMinutes(value.getMinutes());
            editedDateValue.setSeconds(value.getSeconds());
            onChange(editedDateValue);
        } else if (editedValue) {
            onChange(editedValue);
        } else {
            onChange(null);
        }
    };

    return (
        <div className={style.base}>
            <div className={style.date}>
                <SingleDatePicker
                    value={value}
                    onChange={handleChangeDate}
                    error={error}
                    disabledDates={disabledDates}
                    placeholder={datePlaceholder}
                />
            </div>
            <div className={style.time}>
                <TimePickerInput value={value} onChange={onChange} error={error} placeholder={timePlaceholder} />
            </div>
        </div>
    );
};

DateTimePicker.propTypes = {
    value: PropTypes.instanceOf(Date)
};
