import React from "react";
import styles from "./ContentLoader.scss";

export const ContentLoader = ({ height = "100%", width = "100%" }) => {
    return (
        <div className={styles.base} style={{ height, width }}>
            <div className={styles.activity} />
        </div>
    );
};
