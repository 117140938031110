import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { Alert } from "../../../alert/Alert";
import { Button } from "../../../button/Button";
import { ConfirmActionButton } from "../../../button/ConfirmActionButton";
import Form from "../../../form/Form";
import { FormItem } from "../../../form/FormItem";
import { Option, Select } from "../../../form/Select";
import { Textarea } from "../../../form/Textarea";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";
import Section from "../../../section/Section";

const msgKey = "riskTable";

export const EditTaskPopup = ({ task, onSave, onClose, onDelete, context, contextList }) => {
    const [text, setText] = useState(task?.text);
    const [contextId, setContextId] = useState(null);
    const [busyDeleting, setBusyDeleting] = useState(false);
    const [busySaving, setBusySaving] = useState(false);

    const handleSave = () => {
        if (!text?.trim().length) {
            return;
        }
        setBusySaving(true);
        onSave(
            task,
            {
                text: text || task.text,
                contextId: contextId
            },
            () => {
                onClose();
            }
        );
    };

    const handleDelete = () => {
        setBusyDeleting(true);
        onDelete(task, () => {
            onClose();
        });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle subtitle={context.title}>
                <FormattedMessage id={`${msgKey}.editTask`} />
            </PopupTitle>
            <PopupContent>
                <Section divide="small">
                    <Form>
                        <FormItem compact label={<FormattedMessage id={`${msgKey}.description`} />}>
                            <Textarea
                                value={text}
                                maxLength={255}
                                placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </FormItem>
                        <FormItem compact label={<FormattedMessage id={`${msgKey}.riskContext`} />}>
                            <Select value={contextId || context.id} onChange={(val) => setContextId(parseInt(val, 10))}>
                                {contextList &&
                                    contextList.map((c, index) => (
                                        <Option key={index} value={c.data.id}>
                                            {c.data.title}
                                        </Option>
                                    ))}
                            </Select>
                        </FormItem>

                        {contextId && context.id !== contextId ? (
                            <FormItem compact>
                                <Alert type="warning">
                                    <FormattedMessage id={`${msgKey}.riskTaskWillMove`} />
                                </Alert>
                            </FormItem>
                        ) : null}
                    </Form>
                </Section>

                {/* Delete task button */}
                <ConfirmActionButton
                    fill
                    busy={busyDeleting}
                    label={<FormattedMessage id={`${msgKey}.deleteRiskTask`} />}
                    question={<FormattedMessage id={`${msgKey}.deleteRiskTaskConfirmation`} />}
                    onConfirm={handleDelete}
                />
            </PopupContent>
            <PopupButtons>
                <Button type="submit" onClick={handleSave} color="primary" busy={busySaving} disabled={busyDeleting}>
                    <FormattedMessage id={`${msgKey}.saveTask`} />
                </Button>
                <Button onClick={onClose}>
                    <FormattedMessage id={`${msgKey}.cancel`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
