import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import { Button } from "../../../button/Button";
import { CustomSelect, OptionItem } from "../../../form/CustomSelect";
import Form from "../../../form/Form";
import { FormItem } from "../../../form/FormItem";
import { Textarea } from "../../../form/Textarea";
import { Badge } from "../../../misc/Badge";
import { Popup, PopupButtons, PopupContent, PopupTitle } from "../../../notify/Popup";

const msgKey = "riskTable";

export const NewHazardPopup = ({ task, onClose, onCreate }) => {
    const [description, setDescription] = useState(null);
    const [rank, setRank] = useState(null);
    const [measures, setMeasures] = useState(null);
    const [busyCreating, setBusyCreating] = useState(false);

    const handleCreateHazard = () => {
        setBusyCreating(true);
        onCreate(task, { description, rank, measures }, () => {
            onClose();
        });
    };

    return (
        <Popup onClose={onClose}>
            <PopupTitle subtitle={task.text}>
                <FormattedMessage id={`${msgKey}.addHazard`} />
            </PopupTitle>
            <PopupContent>
                <Form>
                    <FormItem compact label={<FormattedMessage id={`${msgKey}.hazard`} />}>
                        <Textarea
                            value={description}
                            placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </FormItem>
                    <FormItem compact label={<FormattedMessage id={`${msgKey}.measures`} />}>
                        <Textarea
                            value={measures}
                            placeholder={<FormattedMessage id={`${msgKey}.writeHere`} />}
                            onChange={(e) => setMeasures(e.target.value)}
                        />
                    </FormItem>
                    <FormItem label={<FormattedMessage id={`${msgKey}.riskAssessment`} />} compact>
                        <CustomSelect
                            value={rank}
                            onChange={setRank}
                            placeholder={<FormattedMessage id={`${msgKey}.selectAssessment`} />}
                        >
                            <OptionItem
                                value={0}
                                symbol={
                                    <Badge fill color="silent">
                                        <FormattedMessage id={`${msgKey}.rank-none`} />
                                    </Badge>
                                }
                            />
                            <OptionItem
                                value={1}
                                symbol={
                                    <Badge fill color="rank-low">
                                        <FormattedMessage id={`${msgKey}.rank-low`} />
                                    </Badge>
                                }
                            />
                            <OptionItem
                                value={2}
                                symbol={
                                    <Badge fill color="rank-mid">
                                        <FormattedMessage id={`${msgKey}.rank-mid`} />
                                    </Badge>
                                }
                            />
                            <OptionItem
                                value={3}
                                symbol={
                                    <Badge fill color="rank-high">
                                        <FormattedMessage id={`${msgKey}.rank-high`} />
                                    </Badge>
                                }
                            />
                        </CustomSelect>
                    </FormItem>
                </Form>
            </PopupContent>
            <PopupButtons>
                <Button
                    type="submit"
                    onClick={handleCreateHazard}
                    color="primary"
                    icon="check"
                    disabled={!description?.trim().length}
                    busy={busyCreating}
                >
                    <FormattedMessage id={`${msgKey}.createHazard`} />
                </Button>
            </PopupButtons>
        </Popup>
    );
};
