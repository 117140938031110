import classNames from "classnames";
import moment from "moment";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { USER_LOG_ENTRY_TYPES } from "../../../../../api/constants";
import { Icon } from "../../../../graphic/Icon";
import styles from "./ValueStep.scss";
import { dateRangeToHourValue } from "../../../../../utils/dateUtils";

const msgKey = "timeTable";

export const ValueStep = ({ date, hour, entry, isClickable }) => {
    const startTime = new Date(entry.startTime);
    const endTime = moment(entry.endTime).subtract(1, "minutes").toDate();
    const isStart = date.getDate() === startTime.getDate() && startTime.getHours() === hour;
    const isEnd = date.getDate() === endTime.getDate() && endTime.getHours() === hour;
    const hoursLength = dateRangeToHourValue(startTime, endTime);
    const minutes =
        isStart && startTime.getMinutes() > 0
            ? startTime.getMinutes()
            : isEnd && endTime.getMinutes() > 0
            ? endTime.getMinutes()
            : 60;
    const createdSecondsAgo = moment.duration(moment().diff(moment(entry.registered))).asSeconds();
    return (
        <div
            className={classNames(styles.step, {
                [styles.start]: isStart,
                [styles.end]: isEnd,
                [styles.new]: createdSecondsAgo <= 30
            })}
            style={{ backgroundColor: USER_LOG_ENTRY_TYPES[entry.type].color, width: `${(minutes / 60) * 100}%` }}
        >
            {isClickable && <div className={styles.clickable}></div>}

            {(isStart && startTime.getHours() < 21) || (startTime.getHours() >= 21 && hour === 0) ? (
                <StepLabel
                    entry={entry}
                    shortened={
                        (startTime.getHours() < 21 && hoursLength <= 3) ||
                        (startTime.getHours() >= 21 && startTime.getHours() + hoursLength - 24 <= 3)
                    }
                />
            ) : null}
        </div>
    );
};

const StepLabel = ({ entry, shortened }) => {
    return (
        <div className={styles.label}>
            <span>
                {shortened ? (
                    <>
                        <FormattedNumber
                            value={dateRangeToHourValue(new Date(entry.startTime), new Date(entry.endTime))}
                        />
                        <FormattedMessage id={`${msgKey}.hoursSuffix`} />
                    </>
                ) : (
                    <>
                        <FormattedNumber
                            value={dateRangeToHourValue(new Date(entry.startTime), new Date(entry.endTime))}
                        />
                        <FormattedMessage id={`${msgKey}.hoursSuffix`} />
                        &nbsp; (<FormattedMessage id={USER_LOG_ENTRY_TYPES[entry.type].shortLabel} />)
                    </>
                )}
            </span>
            {entry.remarks ? (
                <div className={styles.remarks}>
                    <Icon name="comment" />
                </div>
            ) : null}
        </div>
    );
};
