import moment from "moment";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Link } from "react-router-dom";
import { USER_LOG_ENTRY_TYPES } from "../../../../api/constants";
import { ConfirmActionButton } from "../../../button/ConfirmActionButton";
import Col from "../../../grid/Col";
import Row from "../../../grid/Row";
import { KeyValueList } from "../../../list/KeyValueList";
import { Badge } from "../../../misc/Badge";
import { Popup, PopupContent, PopupTitle } from "../../../notify/Popup";
import { Text } from "../../../typography/Text";
import { dateRangeToHourValue } from "../../../../utils/dateUtils";

const msgKey = "timeLogEntry";

export const TimeLogEntry = ({ busyDeleting, entry, onClose, onDelete }) => {
    return (
        <Popup onClose={onClose}>
            <PopupTitle
                subtitle={
                    <>
                        {moment(entry.startTime).format("LLL")} - {moment(entry.endTime).format("LLL")}
                    </>
                }
            >
                <FormattedMessage id={USER_LOG_ENTRY_TYPES[entry.type].label} />,{" "}
                <FormattedNumber value={dateRangeToHourValue(new Date(entry.startTime), new Date(entry.endTime))} />{" "}
                <FormattedMessage id={`${msgKey}.hours`} />
            </PopupTitle>
            <PopupContent>
                <Row>
                    <Col span={24}>
                        <KeyValueList>
                            <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.type`} />}>
                                <Badge fill customColor={USER_LOG_ENTRY_TYPES[entry.type].color}>
                                    <FormattedMessage id={USER_LOG_ENTRY_TYPES[entry.type].label} />
                                </Badge>
                            </KeyValueList.Item>
                            <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.duration`} />}>
                                {dateRangeToHourValue(new Date(entry.startTime), new Date(entry.endTime))}{" "}
                                <FormattedMessage id={`${msgKey}.hours`} />
                            </KeyValueList.Item>
                            <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.startTime`} />}>
                                {moment(entry.startTime).format("LLL")}
                            </KeyValueList.Item>
                            <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.endTime`} />}>
                                {moment(entry.endTime).format("LLL")}
                            </KeyValueList.Item>
                            {entry.vessel && (
                                <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.vessel`} />}>
                                    <Link to={`/vessels/${entry.vessel.id}`}>{entry.vessel.name}</Link>
                                </KeyValueList.Item>
                            )}
                            <KeyValueList.Item label={<FormattedMessage id={`${msgKey}.remarks`} />}>
                                {entry.remarks || (
                                    <Text color="base40">
                                        <FormattedMessage id={`${msgKey}.noRemarks`} />
                                    </Text>
                                )}
                            </KeyValueList.Item>
                        </KeyValueList>
                    </Col>
                    {!!onDelete && (
                        <Col span={24}>
                            <ConfirmActionButton
                                fill
                                busy={busyDeleting}
                                label={<FormattedMessage id={`${msgKey}.deleteEntry`} />}
                                question={<FormattedMessage id={`${msgKey}.deleteQuestion`} />}
                                onConfirm={() => onDelete(entry)}
                            />
                        </Col>
                    )}
                </Row>
            </PopupContent>
        </Popup>
    );
};
