import React from "react";
import classNames from "classnames";
import styles from "./ShieldLogo.scss";

export const ShieldLogo = ({ silent, outline, size, variant }) => {
    return variant === "plain" ? (
        <svg className={classNames(styles.base)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 551 638">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.363 96.082 269.954 0l267.592 96.082s12.967 243.334-21.858 341.324C480.863 535.396 358.311 609.873 269.954 638 181.597 609.873 59.046 535.396 24.22 437.406-10.605 339.416 2.363 96.081 2.363 96.081Zm267.956 83.141-101.011 41.931 15.468-73.767 86.172-34.848v26.929l85.703 34.492 15.496 73.546-101.828-42.066v-26.217Zm.852 286.625-18.635-237.586s-43.415 15.393-152.913 51.62c74.115 83.245 83.453 188.223 83.453 188.223l87.081 42.706v27.435l88.094-43.204s9.338-104.977 83.453-188.222c-109.498-36.227-156.136-52.329-156.136-52.329l-14.397 222.894v-11.537Z"
            />
        </svg>
    ) : (
        <svg
            className={classNames(styles.base, {
                [styles.silent]: silent,
                [styles.outline]: outline
            })}
            style={size ? { width: size, height: size } : null}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 402 472"
        >
            <path
                className={styles.bkg}
                fill="#1761A6"
                d="M394.514 78.506c0-.002 0-.003-4.993.263l4.993-.266-.176-3.316-3.125-1.123-188.521-67.77L201 5.688l-1.691.608L10.788 74.064l-3.125 1.123-.177 3.316 4.993.266a827.375 827.375 0 0 0-4.993-.263v.007l-.002.025-.005.096-.02.377-.07 1.464a942.29 942.29 0 0 0-.25 5.595c-.203 4.849-.465 11.847-.702 20.459-.475 17.219-.851 40.907-.459 66.771.782 51.44 4.604 112.439 17.188 147.888 12.727 35.852 41.295 66.995 74.136 91.553 32.897 24.599 70.547 42.941 102.18 53.023l1.518.484 1.518-.484c31.633-10.082 69.283-28.424 102.18-53.023 32.842-24.558 61.409-55.701 74.136-91.553 12.584-35.449 16.407-96.448 17.188-147.888.393-25.864.016-49.552-.459-66.771-.237-8.612-.499-15.61-.701-20.46a930.218 930.218 0 0 0-.322-7.058l-.019-.377-.005-.096-.001-.025-.001-.007Z"
            />
            <path
                fill="url(#a)"
                fillOpacity=".2"
                fillRule="evenodd"
                d="m201 11 188.521 67.77s9.136 171.63-15.399 240.745C349.587 388.631 263.248 441.161 201 461V11Z"
                clipRule="evenodd"
            />
            <mask
                id="b"
                width="383"
                height="385"
                x="16"
                y="37"
                maskUnits="userSpaceOnUse"
                style={{ maskType: "luminance" }}
            >
                <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M77.984 422H323.79L399 37 16 43.685 77.984 422Z"
                    clipRule="evenodd"
                />
            </mask>
            <g fill="#fff" fillRule="evenodd" clipRule="evenodd" mask="url(#b)">
                <path d="m188.729 172 13.128 167.576v32.065l-62.063-30.473S133.215 267.124 81 208.409C158.143 182.857 188.729 172 188.729 172Z" />
                <path d="m212 190.5-10.857 168.289v31.852l62.063-30.473s6.579-74.044 58.794-132.759C244.857 201.857 212 190.5 212 190.5ZM201.7 137.227l-71.606 29.759 10.897-52.03 60.709-24.58v46.851Z" />
                <path d="m201.257 155.903 71.739 29.67-10.917-51.874-60.822-24.506v46.71Z" />
            </g>
            <defs>
                <linearGradient id="a" x1="296.093" x2="440.868" y1="11" y2="380.609" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#fff" />
                    <stop offset="1" stopColor="#fff" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
