import classNames from "classnames";
import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { AppContext } from "../../../AppWrapper";
import { disableBodyScroll, enableBodyScroll } from "../../../utils/scrollUtils";
import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { Tabs } from "../../nav/Tabs";
import { PopupTitle } from "./PopupTitle";
import styles from "./styles.scss";

export const Popup = ({ type, onClose, size, onClickOutside, children }) => {
    const [isReady, setIsReady] = useState(false); // Used to make sure levels work
    const { popups, setPopups } = useContext(AppContext);
    const baseRef = useRef();
    const hasTabs = React.Children.toArray(children)?.some((child) => child?.type === Tabs);
    const [popupId] = useState(Math.random().toString(36).substring(7));
    const level = popups ? popups?.length - popups?.indexOf(popupId) : 1;

    useEffect(() => {
        setPopups((p) => {
            const updated = [...p];
            updated.push(popupId);
            return updated;
        });
        const baseElement = baseRef?.current;
        disableBodyScroll(baseElement);
        setIsReady(true);

        return () => {
            enableBodyScroll(baseElement);
            setPopups((p) => {
                const updated = [...p];
                return updated.filter((id) => id !== popupId);
            });
        };
    }, [popupId, setPopups]);

    const handleClickOutside = (e) => {
        onClickOutside && onClickOutside(e);
    };

    return createPortal(
        <div
            ref={baseRef}
            className={classNames(styles.base, {
                [styles.sizeWrap]: size === "wrap",
                [styles.withTabs]: hasTabs,
                [styles[`level-${level}`]]: !!level
            })}
            onClick={handleClickOutside}
        >
            <div
                className={styles.box}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                {isReady
                    ? React.Children.map(children, (child) => {
                          if (child?.type === Tabs) {
                              return React.cloneElement(child, {
                                  mode: "popup"
                              });
                          } else if (child?.type === PopupTitle) {
                              return React.cloneElement(child, {
                                  type
                              });
                          } else {
                              return child;
                          }
                      })
                    : null}
                {onClose ? (
                    <Clickable onClick={onClose} className={styles.close}>
                        <Icon name="close" />
                    </Clickable>
                ) : null}
            </div>
        </div>,
        document.body
    );
};
