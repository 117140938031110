import { nb } from "date-fns/locale";
import React, { useContext, useEffect, useState } from "react";
import { DayPicker as ReactDayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { LanguageContext } from "../../../../contexts/Language";
import styles from "./DayPicker.scss";

export const DayPicker = ({ value, onChange, disabledDates }) => {
    const { language } = useContext(LanguageContext);
    const [activeMonth, setActiveMonth] = useState(value);

    useEffect(() => {
        setActiveMonth(value);
    }, [value]);

    return (
        <ReactDayPicker
            mode="single"
            selected={value}
            onSelect={onChange}
            month={activeMonth || new Date()}
            onMonthChange={setActiveMonth}
            showWeekNumber
            locale={language?.locale === "nb" ? nb : undefined}
            captionLayout="dropdown"
            fromYear={(value || new Date()).getFullYear() - 20}
            toYear={(value || new Date()).getFullYear() + 10}
            classNames={styles}
            disabled={disabledDates}
            modifiersClassNames={{
                today: styles.today,
                selected: styles.selected
            }}
        />
    );
};
