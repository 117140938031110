import React from "react";
import { toast as libToast } from "sonner";
import { CheckIcon } from "../../graphic/CheckIcon";
import { Icon } from "../../graphic/Icon";
import styles from "./styles.scss";

export const toast = {
    info: ({ message, description }) =>
        renderToast({
            message,
            description,
            duration: !!description ? 8000 : null,
            icon: <Icon size="fill" name="info" color="info" />
        }),
    success: ({ message, description }) => renderToast({ message, description, icon: <CheckIcon /> }),
    error: ({ message, description }) =>
        renderToast({
            message,
            description,
            duration: 8000,
            icon: <Icon size="fill" name="error-circle" color="error" />
        })
};

const renderToast = ({ message, description, duration, icon }) => {
    libToast(
        <div className={styles.content}>
            <div className={styles.symbol}>
                <div className={styles.icon}>{icon}</div>
            </div>
            <div className={styles.details}>
                <div className={styles.message}>{message}</div>
                {description && <div className={styles.description}>{description}</div>}
            </div>
        </div>,
        {
            position: "bottom-center",
            className: styles.toast,
            duration: duration || 3500
        }
    );
};
