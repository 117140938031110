import React, { useContext } from "react";
import classNames from "classnames";
import style from "./style.scss";

import { Clickable } from "../../button/Clickable";
import { Icon } from "../../graphic/Icon";
import { AppContext } from "../../../AppWrapper";

export const MenuItem = ({ label, icon, iconColor = "primary", symbol, link, selected, count, hidden, onClick }) => {
    const { setShowSidebar } = useContext(AppContext);

    const handleClick = (e) => {
        e.stopPropagation();
        setShowSidebar(false);
        onClick && onClick(e);
    };

    return !hidden ? (
        <Clickable
            className={classNames(style.item, {
                [style.selected]: selected,
                [style.hasSymbol]: !!symbol
            })}
            activeClassName={style.selected}
            onClick={handleClick}
            link={link}
        >
            {/* Icon */}
            {icon && !symbol ? (
                <div className={style.icon}>
                    <Icon name={icon} color={iconColor} />
                </div>
            ) : null}

            {/* Symbol */}
            {symbol ? <div className={style.symbol}>{symbol}</div> : null}

            {/* Label */}
            <div className={style.label}>{label}</div>

            {/* Indicators */}
            <div className={style.indicators}>
                {count ? (
                    <span className={style.counter}>{count}</span>
                ) : (
                    <Icon className={style.arrow} name="chevron-right" />
                )}
            </div>
        </Clickable>
    ) : null;
};
